<div class="back-to-home rounded d-none d-sm-block">
  <a href="#" class="btn btn-icon btn-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- MAINTENANCE PAGE -->
<section class="bg-home d-flex align-items-center" data-jarallax='{"speed": 0.5}'
  style="background-image: url('assets/images/maintenance.jpg');">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 text-center">
        <a href="javascript:void(0)" class="logo h5"><img src="assets/images/vips_logo.png" height="100" alt=""></a>
        <div class="text-uppercase text-white title-dark mt-2 mb-4 maintenance">COMING SOON!</div>
        <p class="text-white-50 para-desc mx-auto para-dark">Thank you for patiently waiting.</p>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-12 text-center">
        <div class="text-center">
          <span id="maintenance" class="timer">{{display}}</span><span class="d-block h6 text-uppercase text-white">Minutes</span>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-12 text-center">
        <a href="#" class="btn btn-primary mt-4"><i class="mdi mdi-backup-restore"></i> Go Back Home</a>
      </div>
    </div>
  </div> <!-- end container -->
</section>
<!--end section-->
<!-- MAINTENANCE PAGE -->