import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';


@Component({
  selector: 'app-index-apps',
  templateUrl: './index-apps.component.html',
  styleUrls: ['./index-apps.component.css']
})

/***
 * Apps Component
 */
export class IndexAppsComponent implements OnInit {

  constructor(private router: Router) {

    

   }

  /***
   * Client Review Owl Slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };

  /**
   * Simple Pricing Data
   */
  simple_pricingData = [
    {
      title: "Be a Rider",
      price: 0,
      list: ["Express Earn", "Express Send ", "Express Receive", "Express Pay", "Express Exchange", "Manage Booking"],
      btn: "Get Started"
    },
    {
      warning: "Best",
      title: "App User",
      price: 0,
      list: ["Express Earn", "Express Send", "Express Receive", "Express Order", "Express Buy", "Express Pay", "Express Exchange", "Express Play"],
      btn: "Get Started"
    },
    {
      title: "Be a Merchant",
      price: 0,
      list: ["Express Earn", "Express Send", "Express Receive", "Express Pay", "Express Exchange", "Manage Store"],
      btn: "Get Started"
    }
  ];

  ngOnInit(): void {


  }

  onClickEarn(){
    this.router.navigate(["page-express-earn"]);
  }
  onClickReceive(){
    this.router.navigate(["page-express-receive"]);
  }
  onClickPay(){
    this.router.navigate(["page-express-pay"]);
  }
  onClickOrder(){
    this.router.navigate(["page-express-order"]);
  }
  onClickPlay(){
    this.router.navigate(["page-maintenance"]);
  }
  onClickSend(){
    this.router.navigate(["page-express-send"]);
  }
  onClickExchange(){
    this.router.navigate(["page-express-exchange"]);
  }
  onClickBuy(){
    // this.router.navigate(["page-express-buy"]);
    this.router.navigate(["page-express-buy"]);
  }
}
