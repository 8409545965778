<!-- Start Hero -->
<section class="bg-half-170 pb-0 bg-light d-table w-100 overflow-hidden"
    style="background: url('assets/images/shapes/shape2.png') top; z-index: 0;" id="home">
    <div class="container">
        <div class="row align-items-center mt-5 mt-sm-0">
            <div class="col-md-6">
                <div class="title-heading text-center text-md-start">
                    <span class="badge rounded-pill bg-soft-primary">Freelancing</span>
                    <h4 class="heading mb-3 mt-2">Grow your freelance <span class="fw-bold">Business</span></h4>
                    <p class="text-muted mb-0 para-dark para-desc mx-auto ms-md-auto">Launch your campaign and benefit
                        from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>

                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-primary">Sign up & Start growing</a>
                        <p class="text-muted mt-1 mb-0">*No Credit Card Required</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="freelance-hero position-relative">
                    <div class="bg-shape position-relative">
                        <img src="assets/images/freelancer/freelancer.png" class="mx-auto d-block img-fluid" alt="">
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Hero -->

<!-- Partners start -->
<section class="py-4 border-bottom">
    <div class="container py-4">
        <app-clients-logo></app-clients-logo>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-12 order-1 order-lg-2">
                <div class="section-title text-center text-lg-start mb-4 mb-lg-0 pb-2 pb-lg-0">
                    <span class="badge rounded-pill bg-soft-primary">Services</span>
                    <h4 class="title mt-3 mb-4">Make more, Manage less</h4>
                    <p class="text-muted para-desc mx-auto ms-lg-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                    <div class="mt-4 d-lg-block d-none">
                        <a href="javascript:void(0)" class="btn btn-primary">See more</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-7 col-12 order-2 order-lg-1">
                <div class="row me-lg-5">
                    <div class="col-md-6 col-12">
                        <div class="row">
                            <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                <div
                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-primary text-center mx-auto">
                                        <i class="uil uil-rocket d-block rounded-pill h3 mb-0"></i>
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark">Targeted <br>
                                            High-quality Leads</a>
                                        <p class="text-muted mt-2 mb-0">It is a long established fact that a reader at
                                            its layout.</p>
                                        <i class="uil uil-rocket text-primary full-img"></i>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12 mt-4 pt-2">
                                <div
                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-primary text-center mx-auto">
                                        <i class="uil uil-airplay d-block rounded-pill h3 mb-0"></i>
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark">Send, Schedule, and
                                            Snooze Emails</a>
                                        <p class="text-muted mt-2 mb-0">It is a long established fact that a reader at
                                            its layout.</p>
                                        <i class="uil uil-airplay text-primary full-img"></i>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end Row-->
                    </div>
                    <!--end col-->

                    <div class="col-md-6 col-12">
                        <div class="row pt-lg-4 mt-lg-4">
                            <div class="col-12 mt-4 pt-2">
                                <div
                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-primary text-center mx-auto">
                                        <i class="uil uil-envelope d-block rounded-pill h3 mb-0"></i>
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark">Library of the Best
                                            Email Templates</a>
                                        <p class="text-muted mt-2 mb-0">It is a long established fact that a reader at
                                            its layout.</p>
                                        <i class="uil uil-envelope text-primary full-img"></i>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12 mt-4 pt-2">
                                <div
                                    class="card border-0 text-center features feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-primary text-center mx-auto">
                                        <i class="uil uil-bell d-block rounded-pill h3 mb-0"></i>
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark">Automated Follow-up
                                            Reminders</a>
                                        <p class="text-muted mt-2 mb-0">It is a long established fact that a reader at
                                            its layout.</p>
                                        <i class="uil uil-bell text-primary full-img"></i>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end Row-->
                    </div>
                    <!--end col-->
                </div>
                <!--end Row-->

                <div class="mt-4 d-lg-none d-block text-center">
                    <a href="javascript:void(0)" class="btn btn-primary">See more</a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row">
            <div class="d-flex">
                <ul ngbNav #nav="ngbNav"
                    class="nav nav-pills flex-column bg-white rounded shadow p-3 mb-0 sticky-bar col-md-4"
                    orientation="vertical">
                    <li ngbNavItem="top" class="nav-item">
                        <a ngbNavLink class="nav-link rounded">
                            <div class="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                <h6 class="mb-0"> <i class="uil uil-postcard me-2 h5"></i>Proposals</h6>
                                <i class="uil uil-angle-right-b"></i>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="developing"
                                role="tabpanel" aria-labelledby="proposal">
                                <h4 class="mb-4">Win More Work</h4>
                                <p class="text-muted mb-0">This is required when, for example, the final text is not yet
                                    available. Dummy text is also known as 'fill text'. It is said that song composers
                                    of the past used dummy texts as lyrics when writing melodies in order to have a
                                    'ready-made' text to sing with the melody.</p>

                                <div class="mt-4">
                                    <a href="javascript:void(0)" class="text-primary h6">Explore Proposals <i
                                            class="uil uil-angle-right-b align-middle"></i></a>
                                </div>

                                <div class="mt-4">
                                    <img src="assets/images/freelancer/proposals.png" class="img-fluid" alt="">
                                </div>
                            </div>
                            <!--end teb pane-->
                        </ng-template>
                    </li>
                    <li ngbNavItem="middle" class="nav-item mt-2">
                        <a ngbNavLink class="nav-link rounded">
                            <div class="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                <h6 class="mb-0"> <i class="uil uil-notes me-2 h5"></i>Contracts</h6>
                                <i class="uil uil-angle-right-b"></i>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="developing"
                                role="tabpanel" aria-labelledby="proposal">
                                <h4 class="mb-4">Protect Your Business</h4>
                                <p class="text-muted mb-0">This is required when, for example, the final text is not yet
                                    available. Dummy text is also known as 'fill text'. It is said that song composers
                                    of the past used dummy texts as lyrics when writing melodies in order to have a
                                    'ready-made' text to sing with the melody.</p>

                                <div class="mt-4">
                                    <a href="javascript:void(0)" class="text-primary h6">Explore Contracts <i
                                            class="uil uil-angle-right-b align-middle"></i></a>
                                </div>

                                <div class="mt-4">
                                    <img src="assets/images/freelancer/contract.png" class="img-fluid" alt="">
                                </div>
                            </div>
                            <!--end teb pane-->
                        </ng-template>
                    </li>
                    <li ngbNavItem="bottom" class="nav-item mt-2">
                        <a ngbNavLink class="nav-link rounded">
                            <div class="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                <h6 class="mb-0"> <i class="uil uil-folder-check me-2 h5"></i>Client CRM</h6>
                                <i class="uil uil-angle-right-b"></i>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="developing"
                                role="tabpanel" aria-labelledby="proposal">
                                <h4 class="mb-4">Stay Organized</h4>
                                <p class="text-muted mb-0">This is required when, for example, the final text is not yet
                                    available. Dummy text is also known as 'fill text'. It is said that song composers
                                    of the past used dummy texts as lyrics when writing melodies in order to have a
                                    'ready-made' text to sing with the melody.</p>

                                <div class="mt-4">
                                    <a href="javascript:void(0)" class="text-primary h6">Explore Projects <i
                                            class="uil uil-angle-right-b align-middle"></i></a>
                                </div>

                                <div class="mt-4">
                                    <img src="assets/images/freelancer/crm.png" class="img-fluid" alt="">
                                </div>
                            </div>
                            <!--end teb pane-->
                        </ng-template>
                    </li>
                    <li ngbNavItem="general" class="nav-item mt-2">
                        <a ngbNavLink class="nav-link rounded">
                            <div class="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                <h6 class="mb-0"> <i class="uil uil-clock me-2 h5"></i>Time Tracking</h6>
                                <i class="uil uil-angle-right-b"></i>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="developing"
                                role="tabpanel" aria-labelledby="proposal">
                                <h4 class="mb-4">Keep It Simple</h4>
                                <p class="text-muted mb-0">This is required when, for example, the final text is not yet
                                    available. Dummy text is also known as 'fill text'. It is said that song composers
                                    of the past used dummy texts as lyrics when writing melodies in order to have a
                                    'ready-made' text to sing with the melody.</p>

                                <div class="mt-4">
                                    <a href="javascript:void(0)" class="text-primary h6">Explore Time Tracking <i
                                            class="uil uil-angle-right-b align-middle"></i></a>
                                </div>

                                <div class="mt-4">
                                    <img src="assets/images/freelancer/time.png" class="img-fluid" alt="">
                                </div>
                            </div>
                            <!--end teb pane-->
                        </ng-template>
                    </li>
                    <li ngbNavItem="medicine" class="nav-item mt-2">
                        <a ngbNavLink class="nav-link rounded">
                            <div class="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                <h6 class="mb-0"> <i class="uil uil-invoice me-2 h5"></i>Invoices</h6>
                                <i class="uil uil-angle-right-b"></i>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="developing"
                                role="tabpanel" aria-labelledby="proposal">
                                <h4 class="mb-4">Get Paid Faster</h4>
                                <p class="text-muted mb-0">This is required when, for example, the final text is not yet
                                    available. Dummy text is also known as 'fill text'. It is said that song composers
                                    of the past used dummy texts as lyrics when writing melodies in order to have a
                                    'ready-made' text to sing with the melody.</p>

                                <div class="mt-4">
                                    <a href="javascript:void(0)" class="text-primary h6">Explore Invoice <i
                                            class="uil uil-angle-right-b align-middle"></i></a>
                                </div>

                                <div class="mt-4">
                                    <img src="assets/images/freelancer/invoice.png" class="img-fluid" alt="">
                                </div>
                            </div>
                            <!--end teb pane-->
                        </ng-template>
                    </li>
                    <li ngbNavItem="orthopadic" class="nav-item mt-2">
                        <a ngbNavLink class="nav-link rounded">
                            <div class="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                <h6 class="mb-0"> <i class="uil uil-exchange-alt me-2 h5"></i>Task Tracking</h6>
                                <i class="uil uil-angle-right-b"></i>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="developing"
                                role="tabpanel" aria-labelledby="proposal">
                                <h4 class="mb-4">Be More Effective</h4>
                                <p class="text-muted mb-0">This is required when, for example, the final text is not yet
                                    available. Dummy text is also known as 'fill text'. It is said that song composers
                                    of the past used dummy texts as lyrics when writing melodies in order to have a
                                    'ready-made' text to sing with the melody.</p>

                                <div class="mt-4">
                                    <a href="javascript:void(0)" class="text-primary h6">Explore Task Tracking <i
                                            class="uil uil-angle-right-b align-middle"></i></a>
                                </div>

                                <div class="mt-4">
                                    <img src="assets/images/freelancer/task.png" class="img-fluid" alt="">
                                </div>
                            </div>
                            <!--end teb pane-->
                        </ng-template>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav" class="ms-4 col-md-8"></div>
            </div>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<section class="section bg-light" style="background: url('assets/images/shapes/shape2.png') center center;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title mb-4 pb-2 text-center">
                    <h4 class="title mb-4">Yea, it actually works.</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<section class="section overflow-hidden">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 order-1 order-md-2">
                <img src="assets/images/freelancer/cta.png" class="img-fluid" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-lg-5">
                    <h4 class="title mb-4">Turn new leads and past clients into more paid work</h4>
                    <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of
                        great importance: human perception is tuned to recognize certain patterns and repetitions in
                        texts. If the distribution of letters visual impact.</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                            Tomorrow</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Create your own skin to match
                            your brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                            class="uil uil-angle-right-b"></i></a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-5 col-12">
                <img src="assets/images/illustrator/envelope.svg" class="img-fluid mx-auto d-block" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title">
                    <div class="alert alert-light alert-pills" role="alert">
                        <span class="badge bg-primary rounded-pill me-1">Apps</span>
                        <span class="content">Download now <i class="uil uil-angle-right-b align-middle"></i></span>
                    </div>
                    <h4 class="title mb-4">Available for your <br> Smartphones</h4>
                    <p class="text-muted para-desc mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                    <div class="my-4">
                        <!-- <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2 me-2"><i class="uil uil-apple"></i>
                            App Store</a> -->
                        <a href="javascript:void(0)" class="btn btn-lg btn-dark mt-2"><i
                                class="uil uil-google-play"></i> Play Store</a>
                    </div>

                    <div class="d-inline-block">
                        <div class="pt-4 d-flex align-items-center border-top">
                            <i-feather name="smartphone" class="fea icon-md me-2 text-primary"></i-feather>
                            <div class="content">
                                <h6 class="mb-0">Install app now on your cellphones</h6>
                                <a href="javascript:void(0)" class="text-primary h6">Learn More <i
                                        class="uil uil-angle-right-b"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->