import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rider-application',
  templateUrl: './rider-application.component.html',
  styleUrls: ['./rider-application.component.css']
})
export class RiderApplicationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
