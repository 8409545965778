<app-header></app-header>
<section class="section pt-5 mt-5">
<div class="container">
   
<h1 class="text-center">
    <img src="../../../assets/vips/mylgu.png" class="l-dark logo-light-mode" height="70px" alt=""></h1>    
<b>Effective: </b><span>02/14/2024</span><br>
<b>Updated as of: </b><span>02/20/2024</span><br><br>
<h1>PRIVACY POLICY</h1>
<p>The leadership, employees, and stakeholders of the Local Government Unit (referred to as "myLGU") highly value the personal data of our citizens and users. myLGU is committed to operating with the utmost principles of accountability, honesty, integrity, and ethics, especially in handling personal data through the myLGU App—a bespoke solution tailored for our citizens.</p>
<p>This Data Privacy Policy outlines our practices in compliance with the Data Privacy Act of 2012 and its Implementing Rules and Regulations, along with directives from the National Privacy Commission (NPC).</p>

<h2>Data Collection</h2>
<p>As part of our mission to provide tailored and efficient services, the myLGU App collects personal and sensitive information from residents and users. This collection is essential for offering a range of municipal services, ensuring compliance with regulatory requirements, and enhancing user experience. Data collected may include:</p>
<ul>
    <li>⬤ Personal details (e.g., complete name, birth date, birth place, and address)</li>
    <li>⬤ Contact information (e.g., mobile number and email address)</li>
    <li>⬤ Valid identification details (e.g., any government-issued identity card)</li>
    <li>⬤ Financial information for service payments or financial assistance programs</li>
    <li>⬤ Tax-related information</li>
    <li>⬤ User interactions through the app, including service requests and feedback</li>
    <li>⬤ Device and usage data such as IP address, browser type, and location for improving app functionality</li>
</ul>

<h2>Purpose of Data Use</h2>
<p>myLGU uses the collected data to:</p>
<ul>
    <li>⬤ Deliver municipal services efficiently</li>
    <li>⬤ Process applications and requests through the myLGU App</li>
    <li>⬤ Improve our services and app functionality</li>
    <li>⬤ Conduct surveys for feedback</li>
    <li>⬤ Manage and mitigate risks</li>
    <li>⬤ Comply with legal and regulatory obligations</li>
    <li>⬤ Enhance communication with citizens about updates, services, and offers</li>
</ul>

<h2>Data Sharing</h2>
<p>myLGU ensures that personal data is accessed and shared responsibly, only with entities involved in providing the services requested through the myLGU App, under strict confidentiality obligations. This includes:</p>
<ul>
    <li>⬤ Sharing within myLGU departments to coordinate services</li>
    <li>⬤ Third-party service providers contracted to assist in delivering services</li>
    <li>⬤ Legal disclosures as required by law or regulatory obligations</li>
</ul>

<h2>Data Security</h2>
<p>We implement comprehensive security measures, including organizational, physical, and technical safeguards, to protect personal data from unauthorized access, alteration, and misuse. These measures are designed to ensure data integrity and confidentiality.</p>

<h2>Retention and Disposal</h2>
<p>Data retention follows regulatory requirements and best practices, ensuring data is kept only as long as necessary for the purposes for which it was collected. Secure disposal methods are used to prevent unauthorized data processing.</p>

<h2>Right of Rectification</h2>
<p>Citizens have rights to access, correct, or delete their personal data within the myLGU App, subject to legal and regulatory constraints. myLGU has established procedures for addressing inquiries and complaints regarding data privacy.</p>

<p>   For any data rectification or deletion concerns, please contact us at <a href="mailto:support@govipcenter.com">support@govipcenter.com</a> </p>

<h2>Contact Information</h2>
<p>For questions or feedback related to our Data Privacy Policy or the myLGU App, please contact us at <a href="https://www.facebook.com/profile.php?id=100094619550716">88 VIP Ventures Corp - Main Facebook Page</a>.</p>

<h2>Updates to the Privacy Policy Statement</h2>
<p>This Data Privacy Statement may be updated to reflect changes in legal requirements or our data processing practices. Citizens are encouraged to review it periodically for the latest information on our privacy practices.</p>

<p>Definitions specific to the local government context and the myLGU App have been adjusted accordingly.</p>
</div>
</section>
<app-footer></app-footer> 