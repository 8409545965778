<app-header></app-header>
<section id="hero" class="d-flex align-items-center">
    <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9 text-center">
          <h1>Geng2x and myJuan Video Tutorials</h1>
        </div>
      </div>

      <div class="row icon-boxes justify-content-center">
        <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
          <div class="icon-box justify-content-center d-block">
            <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/1gBiUZU1nDY" title="Netbank Cash In to Vips Tutorial" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h4 class="title">Instapay Transfer Video Tutorial</h4>
          </div>
        </div>

        <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0" data-aos="zoom-in" data-aos-delay="400">
          <div class="icon-box justify-content-center d-block">
            <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/6yW9JSbGGgg" title="Vips Official Website Walkthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h4 class="title">Bills Payment Video Tutorial</h4>
          </div>
        </div>

        <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
          <div class="icon-box justify-content-center d-block">
            <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/1YUiUrz4p-w" title="Vips Rider - Registration Process" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h4 class="title">Cash in & Cash out Options Video Tutorial</h4>
          </div>
        </div>
        

        <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0 mt-4" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon-box justify-content-center d-block">
              <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/F_81knfNOLM" title="Vips Merchant - Registration Process" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <h4 class="title">How to Register for the myJuan App Video Tutorial</h4>
            </div>
          </div>

        <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0 mt-4" data-aos="zoom-in" data-aos-delay="400">
          <div class="icon-box justify-content-center d-block">
            <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/gIleG_DnF0A" title="Vips Merchant - Store Menu Creation Process" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h4 class="title">How to cash in to your myJuan using Netbank Account Number Video Tutorial</h4>
          </div>
        </div>

        <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0 mt-4" data-aos="zoom-in" data-aos-delay="400">
          <div class="icon-box justify-content-center d-block">
            <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/IIGhmLtd4LA" title="Vips Merchant - How to turn ON and OFF your store availability" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h4 class="title">How to use Delivery Tracker Video Tutorial</h4>
          </div>
        </div>

        <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0 mt-4" data-aos="zoom-in" data-aos-delay="400">
          <div class="icon-box justify-content-center d-block">
            <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/vjfjeXwF2Ls" title="Vips Merchant - Receiving payment verification" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h4 class="title">How to use myJuan Pabili Service Video Tutorial</h4>
          </div>
        </div>

        <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0 mt-4" data-aos="zoom-in" data-aos-delay="400">
          <div class="icon-box justify-content-center d-block">
            <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/8XP2FXRfNTQ" title="Vips Food Order Process Tutorial" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h4 class="title">How to use myJuan Xpress Service Video Tutorial</h4>
          </div>
        </div>

      </div><br><br><br>
      <!-- <div class="row justify-content-center mt-5" data-aos="fade-up" data-aos-delay="100">
        <div class="col-xl-7 col-lg-9 text-center">
          <h2 class="text-primary">Download the App now!</h2>
          <div class="justify-content-center mt-4 gap-2 d-flex" data-aos="fade-up" data-aos-duration="1500">
            <a href="https://play.google.com/store/apps/details?id=com.vipco_wallet&fbclid=IwAR2IRRCTLKDsY1mz9VJpWjtl3E6NvVadZ8pR-GHFR5Z0EZ6d2i5A9MNLA-w&pli=1" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a><br>
            <a href="https://apps.apple.com/ph/app/vips-ph/id6444526599" class="btn btn-outline-primary mt-2"><i class="fa-brands fa-app-store"></i> App Store</a>
        </div>
        </div>
      </div> -->
        
    </div>
    
  </section><br><br><br>
  <div class="position-relative">
    <div class="shape overflow-hidden text-footer">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <app-footer></app-footer>