<app-header></app-header>
<section class="section pt-5 mt-5">
<div class="container">
<h1 class="text-center">VIPS</h1>    
<h2 class="text-center">TERMS AND CONDITIONS</h2><br>
<b>Effective: </b><span>11/19/2022</span><br>
<b>Updated as of: </b><span>11/19/2022</span><br><br>
<p>Please read the following terms and conditions carefully. These shall govern the use of, and access to, the Vips application and software and account (the “Application”) by any party (the “User” to be used interchangeably with “he”, “she”, “you” and “your”). These terms and conditions shall constitute a legal agreement between you and <b>ACM Business Solution Inc.</b>, (“Company”), which is the owner of the Vips Application and Software. 
</p>
<br>
<p>By downloading or using the Application (“App”), you represent that you have read, understood, and accepted all terms and conditions governing access to, and use of the Application (including all modifications, amendments, supplements, and updates thereof), and have agreed to be bound by, and comply with, the same. If you do not agree with the terms and conditions, please do not use, or discontinue your use, of the Application.</p>
<ol><b>1. Eligibility and Qualifications</b></ol>
<p>To create an account and use the Application, the User must key in his/her complete name, be at least eighteen (18) years of age, and provide his/her email address and mobile phone number.</p><br>
<p>The Company reserves the right to set additional qualifications and eligibility requirements as it may deem necessary or desirable and as provided for on its website, https://vipco.io.</p>
<ol><b>2. Vips Account</b></ol>
<span>2.1.Registration</span><br><br>
<p>To use the Application, the User must create an account and register the same with the Application (the
    “Account”). All access to, and use of the Application must be made using and under a registered Account.</p><br>
    <p>To register, the User must provide his/her full name, email address and mobile phone number. The
        Company does not assure perpetual record-keeping of data or transaction history, and may delete User’s
        data when necessary. The use of all information provided by the User shall be governed by the
        Company’s Privacy Policy, which is incorporated as part of these Terms.</p>
<span>2.2.Confidentiality</span><br><br>
<p>The User is solely responsible for maintaining the confidentiality of his/her Account. The User shall
    likewise be solely responsible for all activities and transactions made under his/her Account, and the
    Company shall assume, at all times, that all activities and transactions under the Account were made
    and/or authorized by the User. As such, the User holds the Company free and harmless from any liability,
damage, cost and expenses which he/she may incur as a result of any activity made or used in the
Account.
</p>
<span>2.3.Suspension and Deactivation</span><br><br>
<p>The User can temporarily or permanently suspend his/her Account by following the instructions
    provided in the Application when available. Otherwise, any suspension can be done via email at
    help@govipcenter.org. Being voluntary, the User can automatically reactivate his/her account.</p>
    <br>
    <p>The Company reserves the right to temporarily or permanently suspend an Account, without notice to
        the User, on the following grounds:</p>
<li>a.	Violation of any of these terms and conditions</li>
<li>b.	Fraud or abuse in using or availing the application</li>
<li>c.	Analogous circumstances.</li><br>
<p>In case of Vips-initiated suspension or deactivation, the User has to secure again the approval of the
    Company before his/her account can be reactivated and eventually use the App.</p>
<ol><b>3.	Services</b></ol>
<span>3.1.Overview</span><br><br>
<p>The Service consists of a mobile App that features various payment services and other related
    e-commerce products and functionalities.
</p>
<br>
<p>
    Depending on your country of residence, you may not be able to use all the functions of the Service. It is
    your responsibility to follow those rules and laws in your country of residence and/or country from
    which you access the Services. As long as you agree to and comply with these Terms of Use, Vips grants
    you the personal, non-exclusive, non-transferable, non-sublicensable and limited right to enter and use
    the Site and the Service.
</p>
<span>3.2.Limitations on Payment-Related Services</span><br><br>
<p>Vips may delay an order if you have not provided the legally required personal information, if it reasonably suspects that you are in violation of this Agreement, or if additional personal identifying information is necessary to establish the identity of the user (“KYC”). Until completion of such verification procedures, you may experience delayed processing of related payment service. Vips will designate any such delayed transaction as “pending,” and funds will not be available until the pending transaction is completed. Vips reserves the right to refuse to process, cancel, or to reverse any user transaction (i) as required by law, (ii) in response to a facially valid subpoena, court order, or other government order, or (iii) if reasonably suspects that the transaction is erroneous, or is in violation of this Agreement. You are also responsible for maintaining the confidentiality of your account information, including your password. <br><br>

    IF THERE IS SUSPICIOUS ACTIVITY RELATED TO YOUR ACCOUNT, WE MAY, BUT ARE NOT OBLIGATED TO, REQUEST ADDITIONAL INFORMATION FROM YOU, INCLUDING AUTHENTICATING DOCUMENTS, AND FREEZE ANY TRANSACTIONS PENDING OUR REVIEW. YOU ARE OBLIGATED TO COMPLY WITH THESE SECURITY REQUESTS OR ACCEPT THE TERMINATION OF YOUR ACCOUNT. YOU ARE REQUIRED TO NOTIFY VIPS IMMEDIATELY OF ANY UNAUTHORISED USE OF YOUR ACCOUNT OR PASSWORD, OR ANY OTHER BREACH OF SECURITY, BY EMAIL TO HELP@GOVIPCENTER.ORG ANY USER WHO VIOLATES THESE RULES MAY BE TERMINATED, AND THEREAFTER HELD LIABLE FOR LOSSES INCURRED BY VIPS OR ANY USER OF THE APP. <br><br>
    
    VIPS RESERVES THE RIGHT TO REFUSE TO PROCESS, OR TO CANCEL OR REVERSE, ANY TRANSACTIONS, EVEN AFTER FUNDS HAVE BEEN DEBITED FROM YOUR ACCOUNT(S), IF VIPS SUSPECTS THE TRANSACTION INVOLVES (OR HAS A HIGH RISK OF INVOLVEMENT IN) MONEY LAUNDERING, TERRORIST FINANCING, FRAUD, OR ANY OTHER TYPE OF FINANCIAL CRIME; IN RESPONSE TO A SUBPOENA, COURT ORDER, OR OTHER GOVERNMENT ORDER; IF VIPS REASONABLY SUSPECTS THAT THE TRANSACTION IS ERRONEOUS; OR IF VIPS SUSPECTS THE TRANSACTION VIPS WILL REVERSE THE TRANSACTION. <br><br>
    
    Vips will make reasonable efforts to ensure that payments are processed in a timely manner within our advertised processing times. But Vips makes no representations or warranties regarding the amount of time needed to complete processing which is dependent upon many factors outside of our control. <br><br>
    
    We may suspend your access to Vips Services in accordance with the suspension and termination terms of this Agreement. Suspension or termination shall not affect the payment of fees or other amounts you owe to Vips. In the event that your Vips Account is suspended or terminated, we will immediately cancel all transactions associated with your account, and bar further transactions until resolution or account cancellation. <br><br>
    
    Vips grants You the right to access and use the Service via its mobile application, the App, with the particular features available to You according to Your subscription type. This right is non-exclusive, non-transferable, and limited by and subject to this Agreement. You acknowledge and agree that your access and use of the Service shall be subject to the Terms of Use, any applicable written agreement, or any other applicable laws. <br><br>
    
</p>
<span>3.3.No Warranty.</span><br><br>
<p>We do not represent that Vips and/or the Vips Services will be available without interruption. Although
    we will strive to provide you with continuous operations, we do not guarantee continuous access or that
    there will be no delays, failures, errors, omissions or loss of transmitted information, nor do we
    guarantee that any order will be executed, accepted, recorded, or remain open. <br><br> YOU FURTHER ACKNOWLEDGE THAT INFORMATION YOU STORE OR TRANSFER THROUGH OUR SERVICES MAY BECOME IRRETRIEVABLY LOST OR CORRUPTED OR TEMPORARILY UNAVAILABLE DUE TO A VARIETY OF CAUSES, INCLUDING SOFTWARE FAILURES, PROTOCOL CHANGES BY THIRD-PARTY PROVIDERS, INTERNET OUTAGES, FORCE MAJEURE EVENTS OR OTHER DISASTERS INCLUDING THIRD-PARTY DDOS ATTACKS, SECURITY BREACH, SCHEDULED OR UNSCHEDULED MAINTENANCE, OR OTHER CAUSES EITHER WITHIN OR OUTSIDE OUR CONTROL. YOU ARE SOLELY RESPONSIBLE FOR BACKING UP AND MAINTAINING DUPLICATE COPIES OF ANY INFORMATION YOU STORE OR TRANSFER THROUGH OUR SERVICES. <br> <br> By engaging the services of Vips, you expressly consent to the use of any personal information, sensitive or otherwise, for purposes of facilitating compliance with the requirements of the Philippine Bureau of Internal Revenue (BIR), consistent with the provisions of Republic Act No. 10173 otherwise known as the “Data Privacy Act of 2012.” You also understand that you alone supply all the necessary information in accordance with the requirements needed and Vips merely submits the same for processing pursuant to the E-Filing requirement. You are also aware that upon submission of the accomplished documents, it automatically becomes a public document, the production, and use of which shall be governed by existing statutes. </p>
<span>3.4.Prohibited Business and Prohibited Use</span><br><br>
<p>The following categories of businesses, business practices, and sale items are barred from accessing the
    Services (“Prohibited Businesses”). By opening a Vips account, you confirm that you will not use the
    Services in connection with the following businesses, activities, practices, or items:</p>
<li>a)	Operating as an unlicensed payment, money transmitter, money service, payment service provider, electronic money, or any other financial Services business which requires licensing;</li>
<li>b)	Counterfeit products or any product or service that infringes upon the copyright, trademark, or trade secrets of any third party;</li>
<li>c)	Stolen goods;</li>
<li>d)	Narcotics, controlled substances, prescription and pharmaceutical services, drug paraphernalia, or any substances designed to mimic illegal drugs;</li>
<li>e)	Prostitution or illegal escort services;</li>
<li>f)	Violent acts towards self or others, or activities or items that encourage, promote, facilitate or instruct others regarding the same;</li>
<li>g)	Funding any of the items included on this Prohibited Businesses list;</li>
<li>h)	Extortion, blackmail, or efforts to induce unearned payments;</li>
<li>i)	Unlicensed sale of firearms and certain weapons;</li>
<li>j)	Engaging in deceptive marketing practices; or</li>
<li>k)	any business that violates any law, statute, ordinance or administrative regulation.</li><br><br>
<p>You may not use Vips Services to engage in the following categories of activity (“Prohibited Use”). By opening an account with Vips, you confirm that you will not use your account to do any of the following:
    Violate or assist any party in violating any law, statute, ordinance, regulation or any rule of any self-regulatory or similar organization of which you are or are required to be a member (for example, those laws, rules, or regulations governing financial services, controlled substances, or consumer protections);
    </p>
<br>

<li>a)	Partake in a transaction that involves the proceeds of any unlawful activity;</li>
<li>b)	Defraud or attempt to defraud Vips or other Vips users;</li>
<li>c)	Infringe upon Vips or any third party’s copyright, patent, trademark, or intellectual property rights;</li>
<li>d)	Provide false, inaccurate or misleading information;</li>
<li>e)	Take any action that imposes an unreasonable or disproportionately large load on our infrastructure, or detrimentally interferes with, intercepts, or expropriates any system, data, or information;</li>
<li>f)	Interfere with another individual’s or entity’s access to or use of any of the Vips Services;</li>
<li>g)	Defame, abuse, harass, stalk, threaten or otherwise violate or infringe the legal rights (such as, but not limited to, rights of privacy, publicity and intellectual property) of others;</li>
<li>h)	Publish, distribute or disseminate any unlawful material or information;</li>
<li>i)	Transmit or upload any material to the Vips Site that contains viruses, Trojan horses, worms, or any other harmful or deleterious programs;</li>
<li>j)	Harvest or otherwise collect information from the Vips Apps or Site about others, including without limitation email addresses, without proper consent;</li>
<li>k)	Act as a payment intermediary or aggregator or otherwise resell any of the Services, unless expressly authorized by Vips in writing;</li>
<li>l)	Transfer any rights granted to you under this Agreement;</li>
<li>m)	Use the Vips Account information of another party to access or use the Vips Apps or Site, except in the case of specific merchants and/or applications which are specifically authorized by a user to access such user’s Vips Account and information;</li>
<li>n)	Otherwise attempt to gain unauthorized access to the Vips system, other Vips accounts, computer systems or networks connected to Vips applications, through password mining or any other means;</li>
<li>o)	Engage in transactions involving items that infringe or violate any copyright, trademark, right of publicity or privacy or any other proprietary right under the law;</li>
<li>p)	Take any action that Vips deems as circumventing Vips controls, including, but not limited to, opening multiple Vips Accounts or abusing promotions that Vips may offer from time to time;</li>
<li>q)	US Residents are prohibited from using Vips for money transmission or to conduct any other financial activity requiring licensure or covered by regulations governing financial services.</li><br><br>
<p>Export Controls & Sanctions. Vips Services are subject to the Philippines and international export controls and economic sanctions requirements. By availing of Vips Services, you represent and warrant that you are not a resident of any of the restricted jurisdictions below. Without limiting the foregoing, you may not avail of any of the Vips Services if: (1) you are in, under the control of, or a national or resident of Cuba, Iran, North Korea, Sudan or Syria or any other country subject to United States embargo, UN sanctions, HM Treasury’s financial sanctions regime, or if you, are on the U.S. Treasury Department’s Specially Designated Nationals List or the U.S. Commerce Department’s Denied Persons List, Unverified List, Entity List HM Treasury’s financial sanctions regime; or (2) you intend to use Vips Services for a transaction with persons residing in Cuba, Iran, North Korea, Sudan or Syria or any other country subject to United States embargo or UN sanctions (or a national or resident of one of these countries), or to a person on the Specially Designated Nationals List, Denied Persons List, Unverified List, Entity List, or HM Treasury’s financial sanctions regime.</p>
<ol><b>4.	Customer Support</b> </ol>
<p>Any request, question, concern or dispute regarding the use of the App may be raised by calling the Company’s user customer support department at 09561620725 and help@govipcenter.org.</p>
<ol><b> 5.	Disputes and Complaints</b></ol>
<span>5.1.	Upon the filing of the complaint, the party complaining (“Complainant”) will be given a reference number, which the Complainant shall use in all his future correspondences with the Company regarding the particular dispute. Feedback will be given to the Complainant via email at help@govipcenter.org.</span><br><br>
<span>5.2.	Customer complaints against the User or Vips shall be reported to the Company through its user customer support hotline 09561620725 and help@govipcenter.org. The complaint should be reported within thirty (30) days from the event. Upon the filing of the complaint, the Complainant will receive a notification/message confirming receipt of said complaint. Feedback will be given to the Complainant via email at help@govipcenter.org.</span><br><br>
<span>5.3.	Release of Vips. If you have a dispute with one or more users, you release Vips (and our parent, officers, directors, agents, joint ventures, employees and suppliers) from any and all claims, demands and damages (actual and consequential) of every kind and nature arising out of or in any way connected with such disputes. In addition, this release extends to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if not known by him must have materially affected his settlement with the debtor.</span><br><br>
<span>5.4.	Errors. In the event of an error, whether computer or human error, or whether via our Services, in a purchase order confirmation, in processing your purchase or payment, or otherwise, we reserve the right to correct such error and revise your payment transaction accordingly (including charging the correct price) or to cancel the purchase and refund any amount received.</span><br><br>
<span>5.5.	If you think we have made an error, email us at help@govipcenter.org. In your correspondence, you must give us information sufficient to identify you, your account, and the transaction on which you believe an error occurred. You must contact us within thirty (30) days after the transaction occurred. Within thirty (30) days of receiving your request, we must either correct the error or explain to you why we believe the transaction was correct.</span><br><br>
<span>5.6.	When available, errors may be resolved by refunding the correct amount to the User’s account, or deducting the balance from the User’s account, as the case may be. In either case, the User shall be informed of the resolution of the dispute through text message or in-app messaging, before a refund to, or deduction from the User’s account is made by Vips.</span><br><br>
<span>5.7.	 Service Level Commitment on Complaints and Disputes. Within fifteen (15) business days of our receipt of your complaint, a Customer Assistance Officer will address all points raised in your complaint by sending you an email ("Resolution Notice") in which the Officer will: (i) offer to resolve your Complaint in the way you requested; (ii) make a determination rejecting your complaint and set out the reasons for the rejection; or (iii) offer to resolve your complaint with an alternative solution. In exceptional circumstances, if the Officer is unable to respond to your complaint within 15 business days for reasons beyond the Company's control, the Officer will send you a holding reply indicating the reasons for the delay in answering your complaint and specifying the deadline by which the Officer will respond to your complaint (which will be no later than 30 days from our receipt of your complaint). Any offer of resolution made to you will only become binding on us if accepted by you. An offer of resolution will not constitute any admission by us of any wrongdoing or liability regarding the subject matter of the complaint.</span><br><br>
<span>5.8.	 The Complainant may also escalate the complaint to the BSP in case the Company fails to resolve the dispute within the above service level commitment through the following hotline: BSP – Financial Consumer Protection Department (Tel. No.: (632) 708.7325; Fax No.: (632) 708.7345)</span><br><br>
<span>5.9.	 Time Limitation on Claims. You agree that any claim you may have arising out of or related to your relationship with Vips must be filed within one (1) year after the date of the relevant transaction, whichever is earlier; otherwise, your claim shall be deemed permanently barred.</span><br><br>
<ol><b>6.	Licensing</b></ol>
<p>The Company is duly registered with the Bangko Sentral ng Pilipinas (BSP) as an Operator of Payment Systems, and such activity is subject to the rules and regulations of the Bangko Sentral ng Pilipinas and the Anti-Money Laundering Council (AMLC). As a regulated business, the Company is required to comply with the Anti-Money Laundering Act, which requires the Company to verify the identity of its users, maintain records of currency transactions for up to five years and report certain covered and suspicious transactions. In the event that an account is closed by the Company or at your request, even without completing the onboarding process or performing a transaction, records must be held as prescribed by law.</p>
<ol><b>7.	Intellectual Property</b></ol>
<p>Vips and all logos related to the Vips Services and/or Vips Services are either trademarks, or registered marks of Vips, their registered owners or by the Company. The Intellectual Properties owned by the Company should not be copied, reproduced, modified, republished, uploaded, posted, transmitted, scraped, collected or distributed in any form or by any means, whether manual or automated. The use of any such Intellectual Property on any other App, Site or networked computer environment for any other purpose is strictly prohibited; any such unauthorized use may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties.</p>
<ol><b>8.	Obligation and Warranties of the User</b></ol>
<span>By using the App and availing of Vips   Services, you warrant that:</span><br><br>
<li>a)	You are at least eighteen (18) years old, have the legal capacity to give consent, and the legal capacity and authority to accept and agree to the Terms and Conditions herein set forth; that all information provided are true and accurate; and</li>
<br>
<li>b)	You have fully read and understood the Terms and Conditions herein set forth, and knowingly agreed to be bound by the same.</li>
<br>
<p>By using the App, you undertake to perform the following obligations:</p>
<li>a)	You shall abide by, and comply with the Terms and Conditions herein set forth;</li>
<br>
<li>b)	You shall use the App solely for your own personal use, and shall not use the App for commercial purposes unless expressly allowed by the Company through a separate agreement;</li>
<br>
<li>c)	You have the right to use the App to the extent allowed by the Terms as herein provided, and the ownership of the Application and all other rights are expressly reserved by the Company. As such, the User shall not:</li>
<br>
<li>o	Install the App on any device or operating system not approved by the Company;</li>
<br>
<li>o	Rent, lease, sublicense, distribute or transfer copies of the App or the license of the App for use of to any third parties;</li>
<br>
<li>o	Adapt, decompile, disassemble modify, reverse engineer, translate the App or create derivative works based on the App;</li>
<br>
<li>o	Upload or send any computer viruses, Trojans, worms, or malicious codes to, from, or using the App or the Account;</li>
<br>
<li>o	Impair the normal operation of the App;</li>
<br>
<li>o	Send any email, advertisement or spam emails to, from, or using the App or the Account;</li>
<br>
<li>o	Use another person’s name in registering an Account or in using the App;</li>
<br>
<li>o	Use the App for any illegal or unlawful purpose; or</li>
<br>
<li>o	Use the App to commit any act that will cause undue damage or injury to the Company, Vips, Receiver, other Users or a third party.</li>
<br>
<li>d)	You shall be solely responsible for the security of your Account, and shall protect your login name and password, and shall not disclose the same to third parties, and you shall notify the Company if you have reason to believe that the security of the Account may be, or has been, breached or compromised.</li>
<br>
<ol><b>9.	User Conduct</b></ol>
<p>The Company strives to maintain a respectful user experience through various features and standards in place. The Users and Vips   must be respectful and polite when using the services of the App, and when interacting with one another. <br><br> Notwithstanding the above provision, the Company shall not, in any way, be responsible for the conduct of any User on or off the App. As such, each User shall be solely responsible for all his/her activities in the App, and all his/her interactions with Vips, the receiver, and other Users.</p>
<ol><b>10.	Data Privacy</b></ol>
<p>By using the Application, the User agrees to submit personal data that is true, correct and up to date. The User agrees and consents to the Company’s collection, use, storage, update and processing of his/her personal information, as may be necessary, for a specific period, in order for the Company to provide the Services.  The terms and conditions governing personal data and privacy are provided in Vips Privacy Policy, and is incorporated as part of these Terms. Please review the Privacy Policy, which also governs the Services and informs Users of our data collection practices.</p>
<ol><b>11.	Limitation of Liability</b></ol>
<span>11.1.	IN NO EVENT SHALL WE, OUR RELATED COMPANIES, OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES AND SUPPLIERS OF VIPS BE LIABLE FOR LOST PROFITS OR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH OUR WEBSITE, VIPS SERVICES, OR THIS AGREEMENT (HOWEVER ARISING, INCLUDING NEGLIGENCE). OUR LIABILITY, AND THE LIABILITY OF OUR RELATED COMPANY, OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES AND SUPPLIERS, TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO THE ACTUAL AMOUNT OF DIRECT DAMAGES.</span><br><br>
<span>11.2.	VIPS SERVICES ARE PROVIDED “AS IS” AND WITHOUT ANY REPRESENTATION OF WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY. VIPS, OUR RELATED COMPANIES,  OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES AND SUPPLIERS OF VIPS OR OUR PARENT SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. Vips does not have any control over the products or services that are paid for with Vips Services and Vips cannot ensure that a buyer or a seller you are dealing with will actually complete the transaction or is authorized to do so. Vips does not guarantee continuous, uninterrupted or secure access to any part of Vips Services, and operation of our site may be interfered with by numerous factors outside of our control. Vips will make reasonable efforts to ensure that requests for electronic debits and credits involving bank accounts and check issuances are processed in a timely manner but Vips makes no representations or warranties regarding the amount of time needed to complete processing because Vips Services are dependent upon many factors outside of our control, such as delays in the banking system.</span><br><br>
<ol><b>12.	Force Majeure</b></ol>
<p>We shall not be liable for delays, failure in performance or interruption of Service which result directly or indirectly from any cause or condition beyond our reasonable control, including but not limited to, any delay or failure due to any act of God, act of civil or military authorities, acts of terrorists, civil disturbance, war, strike or other labor dispute, fire, interruption in telecommunications or Internet Services or network provider Services, failure of equipment and/or software, and other catastrophes or any other occurrences which are beyond our reasonable control.</p>
<ol><b>13.	Governing Law</b></ol>
<p>The parties agree that these Terms and Conditions shall be governed by the laws of the Philippines.</p>
<ol><b>14.	Assignment</b></ol>
<p>The Company shall have the right to assign any rights, duties and obligations in these Terms and Conditions to any third party without need of prior notice to, or consent from, the User. The User shall not assign his/her rights and obligations under these Terms and Conditions without the prior written consent of the Company.</p>
<ol><b>15.	Venue</b></ol>
<p>In case of litigation arising from or in connection with these terms and conditions, the action shall be brought only before the appropriate courts of Quezon City, Philippines to the exclusion of all other courts. User irrevocably agrees to waive to the fullest extent, the defense of any inconvenient forum to the maintenance of such action or proceeding.</p>
<ol><b>16.	Severability</b></ol>
<p>Should any provision of these terms and conditions, or portion thereof be declared void, prohibited, ineffectual, or unenforceable under any applicable law by a competent court or authority the same shall not affect any other provision of these terms and conditions, which is otherwise valid and enforceable, provided that the rights of the parties are not adversely affected thereby.</p>
<ol><b>17.	Amendment</b></ol>
<p>The Company reserves the right to amend, modify, vary and change these terms and conditions from time to time. Any amendment of these terms and conditions shall be posted on the App and shall be effective upon the date of said posting.</p>
</div>
</section>
<app-footer></app-footer> 