import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-helpcenter-support-request',
  templateUrl: './helpcenter-support-request.component.html',
  styleUrls: ['./helpcenter-support-request.component.css']
})

/**
 * Helpcenter Support Request Component
 */
export class HelpcenterSupportRequestComponent implements OnInit {
  hideFooter = true;
  constructor(
    private http : HttpClient,
    ) { }

  ngOnInit(): void {
  }
  
}

function send(arg0: { Host: string; Username: string; Password: string; To: string; From: string; Subject: string; Body: string; }) {
  throw new Error('Function not implemented.');
}
