<app-header></app-header>

<section id="" class="pt-5 mt-5">
    <div class="container">
      <!--end row-->
      <div class="row align-items-center">
        
        <!--end col-->
  
        <div class="col-md-8 col-12">
          <div class="section-title ms-lg-5">
            <h4 class="title mb-4">EXPRESS ORDER</h4>
              <br>
            
            <p class="text-muted para-desc mb-0 mx-auto lh-lg int">Ordering through online applications has been with us for how many years and has been a great help. At this moment, our Vips Citizen App introduces an ordering system that has a wide variety of services. This feature can also help you earn rebates that can be used for making transactions. Listed below are the four main services that can be used under Express Order. 
            </p>
            <br>
                <ul class="text-muted mb-0 para-desc mx-auto lh-lg" style="list-style: none;">
                    <li>
                        <span class="text-primary fw-bold">SAME DAY DELIVERY</span> -  Users may order anything that can be delivered within the day at a specific area radius (Delivery time may vary depending on the item/s the user demands to be delivered). 
                    </li>
                    <li>
                        <span class="text-primary fw-bold">SCHEDULED DELIVERY </span> - Some items tend to not be needed immediately. Vips Citizen App introduces this service wherein users may schedule the arrival of their commodity at a specific preferred time, date and place.
                    </li>
                    <li>
                        <span class="text-primary fw-bold">PA-BUY SERVICE</span> - Buying physically may compromise one's health at these times. In this service, users may demand their booked riders specific items that can be bought from a specific store.
                    </li>
                    <li>
                        <span class="text-primary fw-bold">PA-GAWA SERVICE</span> - Maintenance can't be immediately found, but in our Vips Citizen App, help is only a few taps away from you. Users may search and book an expert/professional according to their demand and get a helping hand to fulfill their specific need.
                    </li>
                </ul>

  
          </div>
        </div>
        <!--end col-->
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
          <img src="assets/images/app/order.png" class="img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
  
    <!--end container-->
  </section>
  <br><br>
  <section>
      <div class="container">
        <div class="row">
    
          <div class="col">
            <div class="section-title" id="">
              <h4>FAQs</h4>
            </div>
            <div class="faq-content mt-4 pt-2 pb-5">
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingone">
                      <h6 class="title mb-0 text-dark">What is Express Order?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">Express Order is a premium delivery service that aims to make it easy for residents to arrange deliveries with a few simple clicks. Send documents, goods, packages, and gifts to business partners or loved ones while tracking their real-time delivery.</p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingtwo">
                      <h6 class="title mb-0 text-dark">What is Same Day Delivery?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">It enables the user to immediately schedule a delivery.</p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingthree">
                      <h6 class="title mb-0 text-dark">What is Scheduled Delivery?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">It enables the user to plan a delivery for a certain day and time.</p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">What is Pa-Buy Service?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">It performs the same purpose as the pabili service because it allows the user to book a ride to buy things from non-partner merchants for delivery.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">What is Pa-Gawa Service?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">Users may search for and book experts such as electricians and technicians based on their needs, and they will receive assistance to meet their unique needs for a short time.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">How can I book with Express Order?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">Select the Express Order option on your Vips Citizen App. Enter your pick-up and drop-off locations. Choose between Same-Day and Scheduled Delivery. Enter the recipient's name and phone number, as well as a brief description of the items you want delivered. Confirm your reservation. If a rider is assigned to you, you can call him or her at the phone number indicated if you have any questions or need more instructions. Share the ETA with the receiver of your package so that he or she may plan ahead of time for arrival.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">What are my payment options?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">Express Order bookings can be paid for using cash, credit/debit card, or Vips wallet.</p>
                    </ng-template>
                  </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container pb-5">
          <ul class="list-unstyled text-muted">
              <i-feather name="arrow-left-circle" class="fea icon-sm text-primary me-2"></i-feather>
              <a href="#">Go Back to Homepage</a>
          </ul>
  
      </div>
    </section>
  
   
  
    <app-footer></app-footer> 