<app-header></app-header>

<section id="" class="pt-5 mt-5">
  <div class="container">
    <!--end row-->
    <div class="row align-items-center">
      
      <!--end col-->

      <div class="col-md-8 col-12">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">EXPRESS EARN</h4>
            <br>
          <p class="text-muted para-desc mb-0 mx-auto lh-lg int">In <span
            class="text-primary fw-bold">EXPRESS EARN,</span> users can monitor the transactions made (together with the transaction details) and rebates that has been accumulated and used inside the application. Rebates can be earned through different methods. It can be through making transactions (rebates may vary from every transaction) or through playing our Play-to-Earn feature. Rebates that has been earned can be used in making other transactions inside the application for a lesser hassle and lesser cash-involved transactions.</p>

        </div>
      </div>
      <!--end col-->
      <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
        <img src="assets/images/app/earn.png" class="img-fluid mx-auto d-block" alt="">
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->


  <!--end container-->
</section>
<br><br>
<section>
    <div class="container">
      <div class="row">
  
        <div class="col">
          <div class="section-title" id="">
            <h4>FAQs</h4>
          </div>
          <div class="faq-content mt-4 pt-2 pb-5">
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
              <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingone">
                    <h6 class="title mb-0 text-dark">What is Express Earn?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">Express Earn is a function in our Vips Citizen app that allows you to earn rebates for every qualified transactions completed within the app. These qualifying transactions included actions performed under Express Order, Express Buy, Express Pay, Express Exchange, and Express Play.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingtwo">
                    <h6 class="title mb-0 text-dark">How do I earn rebates?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">A user will receive rebates for each transaction they complete in their Express Order, Express Buy, Express Pay, Express Exchange, and Express Play.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">Does my rebates expire?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">Earned rebates have no expiration date. It is automatically added to your Vips wallet.</p>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
    <div class="container pb-5">
        <ul class="list-unstyled text-muted">
            <i-feather name="arrow-left-circle" class="fea icon-sm text-primary me-2"></i-feather>
            <a href="#">Go Back to Homepage</a>
        </ul>

    </div>
  </section>

 

  <app-footer></app-footer> 