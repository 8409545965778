import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vips-newsletter',
  templateUrl: './vips-newsletter.component.html',
  styleUrls: ['./vips-newsletter.component.css']
})
export class VipsNewsletterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
