import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-express-receive',
  templateUrl: './page-express-receive.component.html',
  styleUrls: ['./page-express-receive.component.css']
})
export class PageExpressReceiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
