import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-express-buy',
  templateUrl: './page-express-buy.component.html',
  styleUrls: ['./page-express-buy.component.css']
})
export class PageExpressBuyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
