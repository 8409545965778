
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-12">
              <div class="rounded p-4 shadow">
                <div class="row">
                  <div class="col-12">
                    <form action="https://formspree.io/f/xlevqqnv" method="POST">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="form-label">Your Name <span class="text-danger">*</span></label>
                            <div class="form-icon position-relative">
                              <i-feather name="user" class="fea icon-sm icons"></i-feather>
                              <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Full Name :">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="form-label">Your Email <span class="text-danger">*</span></label>
                            <div class="form-icon position-relative">
                              <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                              <input name="email" id="email" type="email" class="form-control ps-5"
                                placeholder="Your email :">
                            </div>
                          </div>
                        </div>
                        <!--end col-->
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label class="form-label">Subject</label>
                            <div class="form-icon position-relative">
                              <i-feather name="book" class="fea icon-sm icons"></i-feather>
                              <input name="subject" id="subject" class="form-control ps-5" placeholder="Your subject :">
                            </div>
                          </div>
                        </div>
                        <!--end col-->
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label class="form-label">Comments</label>
                            <div class="form-icon position-relative">
                              <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                              <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                placeholder="Your Message :"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--end row-->
                      <div class="row">
                        <div class="col-sm-12">
                          <input type="submit" id="submit" name="send" class="btn btn-primary" value="Submit">
                        </div>
                        <!--end col-->
                      </div>
                      <!--end row-->
                    </form>
                    <!--end form-->
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!-- <div class="page-next-level"> -->
        

          <!-- <div class="page-next"> -->
            <!-- <nav aria-label="breadcrumb" class="d-inline-block"> -->
              <!-- <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Help Center</a></li>
                <li class="breadcrumb-item active" aria-current="page">Support</li>
              </ul> -->
            <!-- </nav> -->
          </div>
        </div>
      </div>
      <!--end col-->
    <!-- </div> -->
    <!--end row-->
  <!-- </div> -->
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Section -->
<!-- <section class="section"> -->
  
  <!--end container-->

  <!-- <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-envelope-check d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Get in Touch !</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Submit a Request</a>
            </div>
          </div>
        </div>
      </div> -->
      <!--end col-->

      <!-- <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-webcam d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Start a Meeting</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Start Video Chat</a>
            </div>
          </div>
        </div>
      </div> -->
      <!--end col-->
    <!-- </div> -->
    <!--end row-->
  <!-- </div> -->
  <!--end container-->
<!-- </section> -->
<!--end section-->
<!-- End Section -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
