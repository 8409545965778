


<!-- Hero Start -->
<img src="assets/images/app/cool-background2.svg" class="position-absolute container-fluid img002 p-0 m-0" alt="">
<section class="pt-5 pb-5 mb-5 d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 align-items-center">
      <div class="col-lg-6 col-md-7">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="heading mb-3"><span class="text-primary">We Care.<br> We Deliver.<br>We Love You.</span></h1>
          <p class="para-desc text-primary int" data-aos="fade-up-right">Vips Citizen App is the only app you need to cover all your needs - from payments, deliveries, investments and even rewards!</p>
          <div class="d-flex mt-4 gap-2" data-aos="fade-up" data-aos-duration="1500">
            <!-- <a href="page-maintenance" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a> -->
            <a href="https://play.google.com/store/apps/details?id=com.vipco_wallet&fbclid=IwAR2IRRCTLKDsY1mz9VJpWjtl3E6NvVadZ8pR-GHFR5Z0EZ6d2i5A9MNLA-w&pli=1" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a><br>
            <a href="https://apps.apple.com/ph/app/vips-ph/id6444526599" class="btn btn-outline-primary mt-2"><i class="fa-brands fa-app-store"></i> App Store</a>
            </div>
          <div class="d-flex mt-4 gap-2" data-aos="fade-up" data-aos-duration="1500">
            <!-- <a href="page-maintenance" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a> -->
            <a href="https://go.vip-88.com/" class="btn btn-outline-primary mt-2"> Payment Center Login</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4" data-aos="fade-left" data-aos-duration="1000">
          <img src="../../../../assets/vips/Vips Dashboard (1).png" width="600px" alt="" >
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Features Start -->
<section id="services" class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-2 text-primary" data-aos="fade-up" data-aos-duration="1000">App Features</h4>
          <p class="text-muted para-desc mb-0 mx-auto" data-aos="fade-down" data-aos-duration="1000">Explore the next generation E-Wallet, <span
              class="text-primary fw-bold">Vips Citizen</span> app provides all services you can ever think of.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center align-items-center">
      <div class="col-lg-12 col-md-12">
        <div class="row mt-1 pt-2 justify-content-center gap-1">
          <div (click)="onClickEarn()" class="col-md-3 mt-2 cards_1" style="cursor:pointer;"  data-aos="fade-down">
            <div class="d-block features pt-4 pb-4 text-center">
              <div class="text-center text-primary mt-2">
                <!-- <i-feather name="moon" class="fea icon-ex-md text-primary"></i-feather> -->
                <!-- <i class="uil uil-money-withdraw"></i> -->
                <img src="assets/images/app/earn.png" alt="" width="30" class="cursor-pointer">
              </div>
              <div class="flex-1">
                <h4 class="title cursor-pointer">Earn</h4>
                <p class="text-muted para mb-0 cursor-pointer">Use the app, earn points - just like cash! Earned points can be used to transact within the citizen app. </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div (click)="onClickSend()" class="col-md-3 mt-2 cards_1" style="cursor:pointer;"  data-aos="fade-right">
            <div class="d-block features pt-4 pb-4 text-center">
              <div class="text-center text-primary mt-2">
                <!-- <i-feather name="eye" class="fea icon-ex-md text-primary"></i-feather> -->
                <!-- <i class="uil uil-money-insert"></i> -->
                <img src="assets/images/app/send.png" alt="" width="30">
              </div>
              <div class="flex-1">
                <h4 class="title">Send</h4>
                <p class="text-muted para mb-0">Send Vips currency to other users or other platforms with ease, 24/7.</p>
              </div>
            </div>
          </div>

          <div (click)="onClickReceive()" class="col-md-3 mt-2 cards_1" style="cursor:pointer;"  data-aos="fade-down">
            <div class="d-block features pt-4 pb-4 text-center">
              <div class="text-center text-primary mt-2">
                <!-- <i-feather name="feather" class="fea icon-ex-md text-primary"></i-feather> -->
                <!-- <i class="uil uil-money-withdraw"></i> -->
                <img src="assets/images/app/receive.png" alt="" width="30">
              </div>
              <div class="flex-1">
                <h4 class="title">Receive</h4>
                <p class="text-muted para mb-0">Receive wallet transfers from other users/platforms and crypto wallets directly to your Vips Wallet.</p>
              </div>
            </div>
          </div>
          <!--end col-->
          <div (click)="onClickBuy()" class="col-md-3 mt-2 cards_1" style="cursor:pointer;"  data-aos="fade-right">
            <div class="d-block features pt-4 pb-4 text-center">
              <div class="text-center text-primary mt-2">
                <!-- <i-feather name="smartphone" class="fea icon-ex-md text-primary"></i-feather> -->
                <!-- <i class="uil uil-shopping-cart"></i> -->
                <img src="assets/images/app/buy.png" alt="" width="30">
              </div>
              <div class="flex-1">
                <h4 class="title">Buy</h4>
                <p class="text-muted para mb-0">Buy food and other products or items inside our favorite in-app merchant partners.</p>
              </div>
            </div>
          </div>

          <div (click)="onClickOrder()" class="col-md-3 mt-2 cards_1" style="cursor:pointer;"  data-aos="fade-left">
            <div class="d-block features pt-4 pb-4 text-center">
              <div class="text-center text-primary mt-2">
                <!-- <i-feather name="user-check" class="fea icon-ex-md text-primary"></i-feather> -->
                <!-- <i class="uil uil-shopping-bag"></i> -->
                <img src="assets/images/app/order.png" alt="" width="30">
              </div>
              <div class="flex-1">
                <h4 class="title">Order</h4>
                <p class="text-muted para mb-0">Manage your time with our same day and scheduled deliveries together with our Pa-Buy and Pagawa services.</p>
              </div>
            </div>
          </div>
          <!--end col-->
          <!--end col-->

          <div (click)="onClickPay()" class="col-md-3 mt-2 cards_1" style="cursor:pointer;"  data-aos="fade-left">
            <div class="d-block features pt-4 pb-4 text-center">
              <div class="text-center text-primary mt-2">
                <!-- <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather> -->
                <!-- <i class="uil uil-bill"></i> -->
                <img src="assets/images/app/pay.png" alt="" width="30">
              </div>
              <div class="flex-1">
                <h4 class="title">Pay</h4>
                <p class="text-muted para mb-0">Pay all your monthly bills without leaving your home.</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div (click)="onClickExchange()" class="col-md-3 mt-2 cards_1" style="cursor:pointer;"  data-aos="fade-up">
            <div class="d-block features pt-4 pb-4 text-center">
              <div class="text-center text-primary mt-2">
                <!-- <i-feather name="smartphone" class="fea icon-ex-md text-primary"></i-feather> -->
                <!-- <i class="uil uil-exchange"></i> -->
                <img src="assets/images/app/exchange.png" alt="" width="30">
              </div>
              <div class="flex-1">
                <h4 class="title">Exchange</h4>
                <p class="text-muted para mb-0">Buy. Save. Invest. Crypto. Use either your Vips currency or even your earned points to exchange cryptocurrency.</p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div (click)="onClickPlay()" class="col-md-3 mt-2 cards_1" style="cursor:pointer;"  data-aos="fade-up">
            <div class="d-block features pt-4 pb-4 text-center">
              <div class="text-center text-primary mt-2">
                <!-- <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather> -->
                <!-- <i class="uil uil-trophy"></i> -->
                <img src="assets/images/app/play.png" alt="" width="30">
              </div>
              <div class="flex-1">
                <h4 class="title">Play</h4>
                <p class="text-muted para mb-0">Enjoy playing games as you earn Vips currency. Exciting leisure and strategic gaming at your fingertips soon inside your Vips Citizen app.</p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Feature End -->

<!-- Showcase Start -->
<section id="about" class="section bg-light pt-5">
  <div class="container">
    <!--end row-->
    <div class="row align-items-center">
      <div class="col-lg-4 col-md-6 mt-4 pt-2" data-aos="flip-left"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="1000">
        <img src="../../../../assets/vips/Vips Pin.png" height="500px" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-8 col-md-6 mt-4 pt-2">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4 text-primary" data-aos="fade-up">About Us</h4>
          <p class="text-muted para-desc mb-0 mx-0" data-aos="fade-down"> <span
            class="text-primary fw-bold">Vips Citizen App</span> aims  to provide its users with everything they need and more. From delivery services, to an electronic wallet, even up to being your partner in saving and earning. </p>
            <br>
          <ul class="list-unstyled text-muted ms-lg-5" data-aos="fade-right">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Earn - rewards when making transactions using the app</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Receive - from other Vips users</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Send - bank transfers and other remittances</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Order - delivery service and/or pasabuy, pagawa service</li>
              <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Buy - food, grocery items, and more</li>
              <li class="mb-0"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Pay - all your bills using the app</li>
                    <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Exchange - funds, rewards and cryptocurrency</li>
                <li class="mb-0"><span class="text-primary h5 me-2"><i
                      class="uil uil-check-circle align-middle"></i></span>Play - popular games and exclusive content</li>
          </ul>
          <p class="text-muted" data-aos="fade-right">
            Vips Citizen App is another product of VIP88 IT Solution Inc. - the same company that birthed the country's largest payment center, GoVIPCenter. With Vips Citizen App, the company expands its footprint in helping through reaching every corner of the country, and soon, the rest of the world.

          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->


  <!--end container-->
</section>
<!--end section-->
<!-- Showcase End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Price Start -->

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4 text-primary" data-aos="fade-up">Why Choose Us?</h4>
          <p class="text-muted para-desc mb-0 mx-auto" data-aos="fade-up">We offer not just our service but also our hearts in everything we do. Start earning more with<span
              class="text-primary fw-bold"> Vips</span> and let your business grow stronger and better.  Together, let's create a better and wonderful community for everyone.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-right">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-user-md"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Creative & Professional</h5>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-user-md"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-up">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-money-bill"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Quality Service at the Right Price</h5>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-money-bill"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-up">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-clock-nine"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Operate 24/7 Strong Service</h5>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-clock-nine"></i>
          </span>
        </div>
      </div>
      
      <!--end col-->
      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-right">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-luggage-cart"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Secure Transportation</h5>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-luggage-cart"></i>
          </span>
        </div>
      </div>
      
      <!--end col-->
      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-left">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-cloud-wifi"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Wide Service Coverage</h5>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-cloud-wifi"></i>
          </span>
        </div>
      </div>
      
      <!--end col-->
      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-left">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-heart"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Deliver with Love and Care</h5>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-heart"></i>
          </span>
        </div>
      </div>
      
      <!--end col-->

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!--end section-->


<!-- Testi n Download cta start -->
<section id="career" class="section pt-5">

  <div class="container">



    <div class="row">
       
      

      <div class="col-12">

        <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="title mb-4 text-primary" data-aos="fade-up">Join Our Team</h4>
              <p class="text-muted para-desc mx-auto" data-aos="fade-up">Learn more about our promising career opportunities at <span class="text-primary fw-bold">VIP88 IT Solutions Inc. </span>Search below for our current openings today to find the best fit for you and your career goals.</p>
            </div>
          </div>
          <!--end col-->
        </div>

        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center" data-aos="fade-right">
              <div class="card-body">
                <img src="assets/images/client/02.png"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">Technical Support Representative</p>
                <h6 class="text-primary">Business Area: I.T. Department</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center" data-aos="fade-up">
              <div class="card-body">
                <img src="assets/images/client/01.png"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">Full Stack Developers</p>
                <h6 class="text-primary">Business Area: I.T. Department</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi border-0 text-center" data-aos="fade-left">
              <div class="card-body">
                <img src="assets/images/client/02.png"
                  class="img-fluid avatar avatar-small rounded-circle mx-auto shadow" alt="">
                <p class="text-muted mt-4">UI / UX Designer</p>
                <h6 class="text-primary">Business Area: I.T. Department</h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4 text-primary" data-aos="fade-up">Get the App now !</h4>
          <div class="mt-4 gap-2" data-aos="fade-up">
            <!-- <a href="page-maintenance" class="btn btn-primary m-1"><i class="uil uil-apple"></i> App Store</a> -->
            <a href="https://play.google.com/store/apps/details?id=com.vipco_wallet&fbclid=IwAR2IRRCTLKDsY1mz9VJpWjtl3E6NvVadZ8pR-GHFR5Z0EZ6d2i5A9MNLA-w&pli=1" class="btn btn-outline-primary m-2"><i class="uil uil-google-play"></i> Play
              Store</a>
              <a href="https://apps.apple.com/ph/app/vips-ph/id6444526599" class="btn btn-outline-primary"><i class="fa-brands fa-app-store"></i> App Store</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testi n Download cta End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->